'use client';
import useRollbar from '@/hooks/useRollbar';
import { Text, Title } from '@mantine/core';

import React from 'react';
import { ErrorBoundary as ErrorBoundaryLib, FallbackProps } from 'react-error-boundary';

// Componente de fallback customizado
export function DefaultErrorFallback() {
  return (
    <div className="flex flex-col p-4 flex-1 w-full h-full items-center" role="alert">
      <Title className="mt-8">Não foi possível exibir o conteúdo</Title>
      <Text>Tente novamente mais tarde</Text>
    </div>
  );
}

// Componente ErrorBoundary reutilizável
interface ErrorBoundaryProps {
  children: React.ReactElement | React.ReactNode;
  fallbackComponent?: React.ComponentType<FallbackProps>; // Fallback customizável
  resetKeys?: Array<unknown>; // Chaves para reset automático
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  fallbackComponent = DefaultErrorFallback,
  resetKeys,
}) => {
  const rollbar = useRollbar();

  const componentProps = React.isValidElement(children) ? children.props : {};

  // Função para logar o erro com as props e nome do componente
  function logErrorWithDetails(error: Error, info: React.ErrorInfo, componentProps: unknown) {
    const url = typeof window !== 'undefined' ? window.location.href : 'server';

    rollbar.error(error.message || 'ErrorBoundary caught an error', error, {
      componentProps,
      componentStack: info.componentStack,
      url,
    });
  }

  return (
    <ErrorBoundaryLib
      FallbackComponent={fallbackComponent}
      onError={(error, info) => logErrorWithDetails(error, info, componentProps)}
      resetKeys={resetKeys}
    >
      {children}
    </ErrorBoundaryLib>
  );
};

export default ErrorBoundary;
