'use client';
import React from 'react';
import Dashboard from '@/components/Dashboard/Dashboard';
import { useWebsocket } from '@/providers/Websocket/WebsocketProvider';
import { useStore } from '@/stores/store';
import { Container, Flex } from '@mantine/core';
import { DashboardApiValues, DashboardChannelReturn } from './typings';
import { INITIAL_VALUES, getDateInterval, getValuesFromLocalStorage, getStorageKey } from './utils';
import { DateValue } from '@/components/Dashboard/typing';
import { setCookie } from 'cookies-next';
import { DASHBOARD_DATE_RANGE } from '@/utils/storageKeys';
import { useNotificationBanner } from '@/providers/NotificationBannerProvider';
import { User } from '@/typings/user';

export type Props = {
  initialDateInterval?: DateValue;
  hasData?: boolean;
};

const DashboardView: React.FC<Props> = (props) => {
  // State
  const user = useStore((store) => store.user);
  const { joinChannel, isReady, tags, assistants } = useWebsocket();
  const organization = useStore((store) => store.organization);
  const [dashboardApiValues, setDashboardApiValues] =
    React.useState<DashboardApiValues>(INITIAL_VALUES); // Cannot get initial state from local storage (hydration)
  const [dateInterval, setDateInterval] = React.useState(
    getDateInterval(props.initialDateInterval || 'last7Days', organization?.createdAt),
  );
  const { permissionStatus } = useNotificationBanner();

  const shouldShowDashboard = React.useMemo(() => {
    if (props.hasData) return true; // Already defined on the cookies
    if (assistants) {
      // Check if the setup is done (to avoid need to reload to get the cookies)
      const hasAssistantWithChannel = assistants.some(
        (assistant) => assistant.channels && assistant.channels.length > 0,
      );
      return hasAssistantWithChannel;
    }
    // No need to show dashboard for now
    return false;
  }, [props.hasData, assistants]);

  // Callbacks
  const onDashboardUpdate = React.useCallback((data: DashboardChannelReturn) => {
    // Updating data on storage
    const { key, ...dataWithoutKey } = data;
    const localBody = { ...dataWithoutKey, lastRequest: new Date() };
    localStorage.setItem(getStorageKey(key, user as User), JSON.stringify(localBody));
    // Updating local state
    setDashboardApiValues((state) => ({
      ...state,
      [key]: { ...localBody, loading: false, source: 'online' },
    }));
  }, []);

  const onDateChange = React.useCallback(
    (date: DateValue) => {
      // Setting new interval
      const newDateInterval = getDateInterval(date, organization?.createdAt);
      setDateInterval(newDateInterval);
      const maxAge = 1000 * 60 * 60 * 24 * 365; // 1 year for now
      setCookie(DASHBOARD_DATE_RANGE, date, { maxAge });

      // Showing loaders on the cards
      setDashboardApiValues((state) => {
        for (const key in state) {
          const stateKey = key as keyof DashboardApiValues;
          state[stateKey].source = 'storage';
        }
        return state;
      });
    },
    [organization],
  );

  // Effects
  React.useEffect(() => {
    // Starting channel to fetch dashboard data
    if (organization?.id && isReady) {
      // Creating channel setup
      const [start, end] = dateInterval;
      const name = `organization:${organization.id}:dashboard:${start}:${end}`;
      const channel = {
        name,
        callback: onDashboardUpdate,
        callbackOnJoin: false,
        listen: true,
        listenerName: name,
      };

      // Joining Channel
      const leaveChannel = joinChannel<DashboardChannelReturn>(
        channel.name,
        channel.callback,
        channel.callbackOnJoin,
        channel.listen,
        channel.listenerName,
      );

      return () => {
        // Leaving Channel when necessary
        leaveChannel();
      };
    }
  }, [organization?.id, isReady, dateInterval]);

  React.useEffect(() => {
    // Populate state with local storage
    if (user?.id) {
      setDashboardApiValues((state) => ({
        ...state,
        ...getValuesFromLocalStorage(user), // Merge it, because maybe there's something missing on local storage
      }));
    }
  }, [user?.id]);

  // JSX
  return (
    <Container className="max-w-none pb-4 bg-gray-50 min-h-screen">
      <Flex direction="column" gap="md">
        <Dashboard
          {...dashboardApiValues}
          allTags={tags || []}
          hasData={shouldShowDashboard} // Show empty page
          // Date select props
          onDateChange={onDateChange}
          initialDateInterval={props.initialDateInterval}
          // Old page props
          showNotificationBanner={permissionStatus !== 'granted'}
          assistants={assistants || []}
          loadingAssistants={assistants === null}
        />
      </Flex>
    </Container>
  );
};

export default DashboardView;
