import { DateValue } from '@/components/Dashboard/typing';
import { DashboardApiValues } from './typings';
import dayjs from 'dayjs';
import { User } from '@/typings/user';

// Function to add extra local data to the dashboard item
export const generateDashboardItem = <T>(value: T) => ({
  value: value as T,
  loading: true, // Initial loading state
  lastRequest: new Date(),
  source: null,
});

export const INITIAL_VALUES: DashboardApiValues = {
  threadAndMessageCountByDate: generateDashboardItem({
    total: { threadCount: 0, messageCount: 0 },
    date: [],
  }),
  threadCountByUser: generateDashboardItem([]),
  threadCountByTag: generateDashboardItem([]),
  threadCountByMission: generateDashboardItem([]),
};

// Return partial state for what we already have on the localStorage
export const getValuesFromLocalStorage = (user: User) => {
  const storageObject: Partial<DashboardApiValues> = {};
  Object.keys(INITIAL_VALUES).forEach((key) => {
    const localValue = localStorage.getItem(getStorageKey(key, user));
    if (localValue) {
      const value = JSON.parse(localValue);
      value.loading = false;
      value.source = 'storage';
      storageObject[key as keyof DashboardApiValues] = value;
    }
  });
  return storageObject;
};

export const getDateInterval = (
  date: DateValue,
  initialDate?: Date | string,
): [start: string, end: string] => {
  let startDate = dayjs();
  const endDate = dayjs();
  switch (date) {
    case 'today':
      break;
    case 'thisWeek':
      startDate = endDate.startOf('week');
      break;
    case 'last7Days':
      startDate = endDate.subtract(7, 'days');
      break;
    case 'thisMonth':
      startDate = endDate.startOf('month');
      break;
    case 'last30Days':
      startDate = endDate.subtract(30, 'days');
      break;
    case 'all':
      startDate = dayjs(initialDate);
      break;
  }
  return [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')];
};

export const getStorageKey = (key: string, user: User) => `${key}-${user.id}`;
